var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import AuthService from './AuthService';
import { userApi, useSetCurrentUserCache } from './user';
export const AuthContext = createContext(null);
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('Wrap app with AuthProvider');
    }
    return context;
};
const authService = new AuthService();
export const AuthProvider = ({ children, history, useConfig, tokenType, }) => {
    const { pathname } = useLocation();
    const { config } = useConfig();
    const queryClient = useQueryClient();
    const setCurrentUserCache = useSetCurrentUserCache();
    const [error, setError] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const initialize = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const user = yield userApi.getCurrentUser();
            setCurrentUserCache(user);
            setCurrentUser(user);
            setIsLoggedIn(true);
            if (pathname.includes('login'))
                history.replace('/');
        }
        catch (_a) {
            const params = new URLSearchParams(location.search);
            if (!params.get('code') && !pathname.includes('login')) {
                history.push('/login');
            }
        }
    }), [setCurrentUserCache, pathname, history]);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
            if (config) {
                if (params.get('code')) {
                    try {
                        setIsLoading(true);
                        if (pathname.includes('itsme')) {
                            yield authService.getToken(config.itsMe.type, params.get('code'), tokenType);
                        }
                        else {
                            yield authService.getToken(config.auth.type, params.get('code'), tokenType, pathname.includes('admin') ? 'auth_code_admin' : 'auth_code');
                        }
                        yield initialize();
                    }
                    catch (err) {
                        setError(err);
                    }
                    finally {
                        setIsLoading(false);
                    }
                }
                if (params.has('error') || params.has('error_description')) {
                    setError({
                        status: 400,
                        message: params.get('error'),
                        url: location.search,
                        errors: [params.get('error_description')],
                        extraProperties: params.get('extraProperties'),
                    });
                }
            }
        });
        handleLogin();
    }, [config, initialize, tokenType, history]);
    const logout = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            yield authService.logout();
            history.replace('/login');
            queryClient.clear();
        }
        finally {
            setIsLoading(false);
            setIsLoggedIn(false);
        }
    }), [queryClient]);
    const value = useMemo(() => ({
        isLoggedIn,
        initialize,
        logout,
        isLoading,
        error,
        currentUser,
    }), [isLoggedIn, logout, initialize, isLoading, error, currentUser]);
    return React.createElement(AuthContext.Provider, { value: value }, children);
};
